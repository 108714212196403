import React from "react";
import { CheckIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";
import { LinkUnderlineComponent } from "./LinkUnderlineComponent";
import { CenterComponent } from "./CenterComponent";
import MarginComponent from "./MarginComponent";

export function PriceCardComponent({ data, price, show, mn = "mt-6", hide }) {
  const details = show && (
    <MarginComponent small="sm:mt-6" margin="mt-4">
      <CenterComponent>
        <LinkUnderlineComponent href="/">Подробнее</LinkUnderlineComponent>
      </CenterComponent>
    </MarginComponent>
  );

  const list = data && (
    <ul role="list" className={`${mn} text-sm space-y-6`}>
      {data.map((item) => (
        <li className="flex items-center">
          <CheckIcon
            className="flex-shrink-0 w-6 h-6 text-green-500"
            aria-hidden="true"
          />
          <span className="ml-3 text-gray-500">{item.name}</span>
        </li>
      ))}
    </ul>
  );

  const cost = price && (
    <div className="flex-1">
      <h3 className="font-semibold text-gray-900">{price.name}</h3>
      <p className="absolute top-0 py-1.5 px-4 bg-green-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
        Популярное
      </p>
      <p className="mt-4 flex items-baseline text-gray-900">
        <span className="text-lg font-extrabold tracking-tight">
          {price.cost}
        </span>
        <span className="ml-1 text-sm font-semibold">/ {price.days}</span>
      </p>
      {!hide && <p className="mt-6 text-gray-500">Что входит в цену</p>}
    </div>
  );

  return (
    <>
      <div className="relative h-full p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col">
        {cost}
        {list}
        {details}
      </div>
    </>
  );
}
